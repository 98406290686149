.pwaModalContent h2 {
	margin-bottom: 1rem;
	text-align: center;
}

.pwaModalContent p {
	margin-bottom: 2rem;
	text-align: center;
}

.pwaModalContent img {
	max-width: 5rem;
	display: block;
	margin: 0 auto 1rem;
}

.pwaModal {
	display: flex;
	padding: 0 1rem;
	justify-content: flex-end;
}

.pwaModal svg {
	cursor: pointer;
	color: var(--color-primary);
}

.pwaModalContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
	text-align: center;
}

.pwaModalContainer h4 {
	font-size: 1.5rem;
	margin: 1rem 0;
}

.pwaModalContainer .gray {
	background: #ddd;
	margin-bottom: 0.5rem;
}

.pwaModalContainer .gray svg {
	margin-left: 1rem;
}
