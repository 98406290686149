html {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

body,
#load-cont {
	background-image: url(./assets/backgrounds/fond-admin.png);
	font-family: "Poppins-Regular";
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 100%;
}

#root {
	height: 100%;
	background-color: var(--background-color);
}

html {
	--color-primary: #0a9bb3;
	--background-color: #f2f2f4;
	--background-color-lighten: #e1e1e1;
	--background-color-darken: #eaeaea;
	--background-color-rgb: 242, 242, 244;
	--background-color-secondary: #ffffff;
	--background-color-secondary-rgb: 255, 255, 255;
	--main-menu-bg: white;
	--main-menu-shadow: #cbcbcb;
	--shadow: #cbcbcb;
	--main-menu-nav-links-cont-bg: #f2f2f4;
	--main-menu-nav-links-btn-bg: white;
	--tiles-bg-color: #ffffff;
	--font-color: black;
	--font-color-rgb: 0, 0, 0;
	--color-primary-font-color: #ffffff;
	--color-primary-invert-icons: 1;
	--input-color: #f2f2f4;
	--toastify-toast-width: auto;
	--radius-0: 0px;
	--radius-1: 6px;
	--radius-2: 12px;
	--radius-3: 18px;
	--radius-4: 24px;
	--radius-5: 30px;
	--bg-color-1: #cfcfcf;
	--bg-color-2: #d4d4d4;
	--bg-color-3: #e0e0e0;
	--bg-color-4: #e4e3e1;
}

.theme-dark * {
	--background-color: #2c2c2c;
	--background-color-rgb: 44, 44, 44;
	--background-color-lighten: #464646;
	--background-color-darken: #323232;
	--background-color-secondary: #1f1f1f;
	--background-color-secondary-rgb: 31, 31, 31;
	--main-menu-bg: #252525;
	--main-menu-shadow: #202020;
	--shadow: #202020;
	--main-menu-nav-links-cont-bg: #1f1f1f;
	--main-menu-nav-links-btn-bg: #2c2c2c;
	--tiles-bg-color: #1f1f1f;
	--font-color: white;
	--font-color-rgb: 255, 255, 255;
	--input-color: #2c2c2c;
	--bg-color-1: #1b1c1e;
	--bg-color-2: #1f1f1f;
	--bg-color-3: #2b2b2b;
	--bg-color-4: #303030;
}

.changing-theme *,
.changing-theme * * {
	transition-delay: 0s;
	transition-duration: 0.3s !important;
	animation-duration: 0.3s !important;
	animation-delay: 0s;
}

.ReactModal__Overlay {
	z-index: 10;
}

.ReactModal__Overlay > div {
	border-radius: 1rem !important;
}

/*
** -- Toasts
*/

.Toastify__toast-container > div {
	background-color: var(--background-color);
	color: var(--font-color);
}

/*
** -- Toasts
*/

.Toastify__toast-container > div {
	background-color: var(--background-color);
	color: var(--font-color);
}

/* Marker.io Feedback btn
*/

iframe#feedback-button {
	transform: rotateZ(-90deg) translateX(50%) translateY(0px) scale(0.8) !important;
}
/*
** -- Sentry Report Dialog
*/

.sentry-error-embed {
	position: relative;
	border-radius: 12px;
}

.sentry-error-embed .close {
	background-color: red;
	padding: 0.5rem 1.2rem !important;
	line-height: 10px;
	border-radius: 6px;
	color: white;
	margin-top: 10px !important;
}

.App {
	height: 100%;
	color: var(--font-color);
	background-color: var(--background-color);
}

.wrapper {
	height: 100%;
	background-image: url(./assets/backgrounds/fond-admin.png);
	font-family: "Poppins-Regular";
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.color-primary {
	color: var(--color-primary);
}

.no-wrap {
	flex-wrap: nowrap;
	white-space: nowrap;
}

.cont {
	background-color: var(--background-color-secondary);
	padding: 10px;
	border-radius: 12px;
}

vr {
	height: 100%;
	width: 1px;
	background-color: var(--background-color-darken);
}

.radius-0 {
	border-radius: var(--radius-0) !important;
}

.radius-1 {
	border-radius: var(--radius-1) !important;
}

.radius-2 {
	border-radius: var(--radius-2) !important;
}

.radius-3 {
	border-radius: var(--radius-3) !important;
}

.radius-4 {
	border-radius: var(--radius-4) !important;
}

.radius-5 {
	border-radius: var(--radius-5) !important;
}

.button-bg {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.bg-color {
	background-color: var(--background-color);
}

.bg-color-secondary {
	background-color: var(--background-color-secondary);
}

.color-grey,
.text-infos {
	color: grey;
}

.scroll-cont.no-cur {
	margin: 0px -15px;
	width: calc(100% + 15px) !important;
}

.infinite-scroll-end-msg {
	color: grey;
}

.btn {
	display: inline-block;
	width: 100%;
	border: none;
	border-radius: 5px;
	background-color: white;
	background-color: var(--background-color-secondary);
	color: var(--font-color);
	text-align: center;
	padding-top: 5px;
	padding-bottom: 5px;
	line-height: 1rem;
	font-size: 0.9375rem;
	cursor: pointer;
	vertical-align: middle;
	transition-duration: 0.2s;
}

.unstyled {
	padding: 0;
	margin: 0;
	background-color: transparent;
	border: none;
	color: var(--font-color);
}

.placeholder {
	background-color: unset;
}

.btn:hover {
	filter: brightness(0.9);
}

.btn-red {
	background-color: #ff0a18;
	color: white;
}

.btn-blue {
	background-color: #0080b9;
	color: white;
}

.btn-grey {
	background-color: #c1bfc1;
	color: white;
}

.btn-green {
	background-color: rgb(0, 185, 83);
	color: white;
}

.btn-black {
	background-color: rgb(25, 25, 25);
	color: white;
}

.btn-black:hover {
	color: white;
}

.btn-yellow {
	background-color: #ffd300;
	color: white;
}

.btn-primary {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.btn-bg {
	background-color: var(--background-color-darken);
	color: var(--font-color);
}

.btn-bg:hover {
	color: var(--font-color);
	background-color: var(--background-color);
}

.disabled,
.disabled * {
	pointer-events: visible !important;
	cursor: not-allowed !important;
}

.loading div {
	content: "";
	background-image: linear-gradient(
		90deg,
		var(--background-color) 0%,
		transparent 50%,
		var(--background-color)
	);
	animation: tileLoad 4s infinite ease-in-out alternate-reverse;
	width: 80%;
	height: 8px;
	border-radius: 5px;
}

.loading.secondary div {
	background-image: linear-gradient(
		90deg,
		var(--background-color-secondary) 0%,
		transparent 50%,
		var(--background-color-secondary)
	);
}

.loading div:not(:last-child) {
	margin-bottom: 10px;
}

@keyframes tileLoad {
	0% {
		background-position-x: 0%;
	}

	100% {
		background-position-x: 500px;
	}
}

/*
** -- errors cont
*/

.errors-cont {
	background-color: #c5030b;
	color: white;
	padding: 5px;
	border-radius: 6px;
}

.errors-cont:empty {
	display: none;
}

/*
** -- Overload
*/

a {
	color: var(--links-color);
	text-decoration: none;
}

a:hover {
	color: unset;
}

.cursor-pointer {
	cursor: pointer;
}

.btn.btn-primary,
.btn-primary {
	background-color: var(--color-primary);
}

p {
	margin-bottom: 0;
}

b,
strong {
	font-weight: unset;
	font-family: "Poppins-SemiBold";
}

.text-sm {
	font-size: 0.8rem;
}

.block {
	display: block;
}

.d-contents {
	display: contents;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.overflow-y-hidden {
	overflow-y: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
}

/*
** -- Inputs
*/

input,
textarea {
	background-color: var(--input-color);
	accent-color: var(--color-primary);
	color: var(--font-color);
	border-radius: 5px;
	border: none;
	padding: 5px;
}

/* input:focus,
textarea:focus {
	outline: 1px solid var(--color-primary);
} */

/* .form-input:not(:last-child)
{
	margin-right: 10px;
} */

input:disabled,
button:disabled {
	pointer-events: visible !important;
	cursor: not-allowed !important;
	opacity: 0.6;
}

/*
**	-- ScrollBar
*/

::-webkit-scrollbar {
	width: 8px;
	height: 4px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background: #e0e0e0a1;
	background: var(--color-primary);
}

::-webkit-scrollbar-corner {
	background: transparent;
}

/*
** -- DEBUG
*/

.box-border {
	border: 1px solid red;
}
